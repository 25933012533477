import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Nikton
			</title>
			<meta name={"description"} content={"Досвід гольфу в найкращому вигляді"} />
			<meta property={"og:title"} content={"Nikton"} />
			<meta property={"og:description"} content={"Досвід гольфу в найкращому вигляді"} />
			<meta property={"og:image"} content={"https://vortexicobels.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vortexicobels.com/img/10344957.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vortexicobels.com/img/10344957.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vortexicobels.com/img/10344957.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vortexicobels.com/img/10344957.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vortexicobels.com/img/10344957.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vortexicobels.com/img/10344957.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="80px 0 90px 0"
			md-padding="80px 0 50px 0"
			overflow-x="hidden"
			overflow-y="hidden"
			quarkly-title="Hero-14"
		>
			<Override slot="SectionContent" width="100%" min-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				md-flex-direction="column"
				md-justify-content="flex-end"
				md-align-items="flex-end"
				sm-z-index="2"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					width="60%"
					padding="80px 50px 80px 50px"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					sm-padding="50px 25px 50px 25px"
					sm-position="relative"
					sm-z-index="2"
				>
					<Text margin="0px 0px 20px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond" color="--primary">
						Гольф-клуб
					</Text>
					<Text margin="0px 0px 30px 0px" font="normal 600 72px/1.2 --fontFamily-serifGaramond" sm-font="normal 600 42px/1.2 --fontFamily-serifGaramond">
						Nikton{"\n\t\t\t\t "}
					</Text>
					<Text margin="0px 0px 60px 0px" font="--base">
					Ми раді вітати вас у гольф-клубі Nikton, де пристрасть до гольфу поєднується з красою природи. Наш клуб прагне забезпечити неперевершений досвід гри в гольф для гравців усіх рівнів. Розташований серед пишних ландшафтів і ретельно доглянутих зелених насаджень, гольф-клуб Nikton пропонує спокійне і складне середовище, яке надихне вас на гру і порадує ваші почуття.
					</Text>
					<Button
						background="--color-primary"
						border-radius="4px"
						padding="16px 28px 16px 28px"
						font="--base"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="solid"
						border-color="--primary"
						hover-color="--darkL1"
						hover-background="rgba(237, 97, 26, 0.03)"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
					>
						Запланувати візит
					</Button>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					width="40%"
					justify-content="flex-end"
					align-items="center"
					md-margin="-120px 0px 0px 0px"
					md-width="60%"
					sm-position="relative"
					sm-z-index="1"
				>
					<Image
						src="https://vortexicobels.com/img/1.jpg"
						display="block"
						margin="0px -100px 0px 0px"
						height="100%"
						md-margin="0px 0 0px 0px"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-7">
			<Text margin="0px 0px 35px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
			Переваги Nikton
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 40px"
				md-grid-template-columns="1fr"
				md-grid-gap="50px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
							Винятковий дизайн поля
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Наше поле майстерно спроектоване, щоб запропонувати складну, але приємну гру для гольфістів усіх рівнів. Стратегічне планування гарантує, що кожна лунка пропонує унікальний виклик.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
							Об'єкти світового класу
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Від повністю обладнаного професійного магазину до елегантного клубного будинку - кожен аспект Nikton Golf Club розроблений з думкою про ваш комфорт і зручність. Насолоджуйтесь вишуканими стравами, розслаблюючим лаунжем та першокласними роздягальнями.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					margin="0px 0px 0 0px"
					md-flex-direction="column"
					flex-direction="column"
					justify-content="space-between"
					md-justify-content="flex-start"
				>
					<Box
						min-width="100px"
						min-height="100px"
						md-margin="0px 0px 30px 0px"
						margin="0px 0px 0 0px"
						display="flex"
						flex-direction="column"
						md-width="100%"
						md-justify-content="flex-start"
					>
						<Box min-width="100px" min-height="100px">
							<Text margin="0px 0px 10px 0px" font="normal 600 24px/1.2 --fontFamily-sans" lg-margin="0px 0px 15px 0px">
							Професійні інструкції
							</Text>
							<Text
								margin="0px 0px 20px 0px"
								font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
								lg-margin="0px 0px 18px 0px"
								color="#626970"
								md-margin="0px 0px 25px 0px"
							>
								Наша команда сертифікованих PGA інструкторів готова допомогти вам покращити вашу гру. Незалежно від того, чи шукаєте ви приватні уроки або групові клініки, наші інструктори пропонують персоналізовані тренування, пристосовані до ваших потреб.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px" quarkly-title="Advantages/Features-24">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 48px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					lg-width="100%"
				>
					Приєднуйтесь до нас сьогодні
				</Text>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 22px" sm-margin="0px 0px 0px 12px">
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
						Не пропустіть шанс випробувати гольф-клуб Nikton на собі. Незалежно від того, чи хочете ви стати членом клубу, чи просто насолодитися днем на зеленому полі, ми запрошуємо вас дізнатися, що робить наш клуб таким особливим. Завітайте до нас і дізнайтеся, чому Nikton Golf Club - ідеальне місце для вашої наступної пригоди в гольфі.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://vortexicobels.com/img/2.jpg"
					max-width="400px"
					border-radius="8px"
					height="100%"
					object-fit="cover"
					lg-width="100%"
					lg-max-width="none"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});